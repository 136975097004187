// extracted by mini-css-extract-plugin
export var baseBox = "BaseBox-module--base-box--GL-Tl";
export var cardBody = "BaseBox-module--card-body--tKc-v";
export var cardFooter = "BaseBox-module--card-footer--nS4UE";
export var cardFooterSmall = "BaseBox-module--card-footer-small--eSWvR";
export var cardText = "BaseBox-module--card-text--kZv0Q";
export var cardTextNoHeight = "BaseBox-module--card-text-no-height--U9o2N";
export var cardTextSmall = "BaseBox-module--card-text-small--h6FSw";
export var cardTitle = "BaseBox-module--card-title--fJeU9";
export var cardTitleSmall = "BaseBox-module--card-title-small--2Hw1W";
export var gatsbyRespImageBackgroundImage = "BaseBox-module--gatsby-resp-image-background-image--87Iac";
export var gatsbyRespImageImage = "BaseBox-module--gatsby-resp-image-image--VJDtG";
export var gatsbyRespImageWrapper = "BaseBox-module--gatsby-resp-image-wrapper--XfKwK";
export var mainContent = "BaseBox-module--main-content--Ux1nE";
export var readMorePosts = "BaseBox-module--read-more-posts--LnnhF";
export var small = "BaseBox-module--small--pPcEs";